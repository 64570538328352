@media(min-width: 900px){
html, body{
  background-color: #f1f5f7!important;
  scroll-behavior: smooth!important;
  color: #636e75!important;
}

.cstbox{    
  color: rgba(0, 0, 0, 0.87)!important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  display: flex!important;
  flex-direction: column!important;
  position: relative!important;
  min-width: 0px!important;
  overflow-wrap: break-word!important;
  background-color: rgb(255, 255, 255)!important;
  background-clip: border-box!important;
  border: 0px solid rgba(0, 0, 0, 0.125)!important;
  border-radius: 0.75rem!important;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.675rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.65rem -0.0625rem!important;
  overflow: visible!important;
  cursor: pointer!important;
  padding-top: 0%!important;
  padding-bottom: 1rem!important;
  padding-left: 1rem!important;
  padding-right: 2rem!important;
  height: 100%!important;
}
.ltitle{
  font-size: 1rem!important;
  line-height: 1.625!important;
  font-weight: 700!important;
  letter-spacing: 0.0075em!important;
  opacity: 1!important;
  text-transform: capitalize!important;
  vertical-align: unset!important;
  text-decoration: none!important;
  color: rgb(52, 71, 103)!important;
  padding-left: 0.4rem!important;
}

/* width */
::-webkit-scrollbar {
  width: 5px!important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1!important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888!important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555!important;
}

.App {
  text-align: center!important;
}

.App-logo {
  height: 40vmin!important;
  pointer-events: none!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear!important;
  }
}

.App-header {
  background-color: #282c34!important;
  min-height: 100vh!important;
  display: flex!important;
  flex-direction: column!important;
  align-items: center!important;
  justify-content: center!important;
  font-size: calc(10px + 2vmin)!important;
  color: white!important;
}

.App-link {
  color: #61dafb!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg)!important;
  }
  to {
    transform: rotate(360deg)!important;
  }
}

.main {
  flex-grow: 1!important;
  padding: 24px!important;
  width: 100%!important;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto!important;
  overflow-x: auto!important;
}

body {
  margin: 0!important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif!important;
}

html,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
i,
a,
button,
input,
label,
li {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif!important;
  letter-spacing: 1px!important;
}

.bxdivider {
  margin: 10px 0 10px 0!important;
  flex-shrink: 0!important;
  border-top: 0px solid rgba(0, 0, 0, 0.12)!important;
  border-right: 0px solid rgba(0, 0, 0, 0.12)!important;
  border-left: 0px solid rgba(0, 0, 0, 0.12)!important;
  background-color: transparent!important;
  height: 0.0825rem!important;
  border-bottom: none!important;
  opacity: 0.25!important;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  )!important;
}
.custom-card {
  color: rgba(0, 0, 0, 0.87)!important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  display: flex!important;
  flex-direction: column!important;
  position: relative!important;
  min-width: 0px!important;
  overflow-wrap: break-word!important;
  background-color: rgb(255, 255, 255)!important;
  background-clip: border-box!important;
  border: 0px solid rgba(0, 0, 0, 0.125)!important;
  border-radius: 0.75rem!important;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.675rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.65rem -0.0625rem!important;
  overflow: visible!important;
  cursor: pointer!important;
  padding-bottom: 0%!important;
}
.custom-card-head {
  font-size: 16px!important;
  font-weight: 500!important;
  border-bottom: 1px solid #e2e5ec!important;
  padding: 15px!important;
}
.custom-head {
  font-size: 1rem!important;
  line-height: 1.625!important;
  font-weight: 700!important;
  letter-spacing: 0.0075em!important;
  opacity: 1!important;
  text-transform: capitalize!important;
  vertical-align: unset!important;
  text-decoration: none!important;
  color: rgb(52, 71, 103)!important;
  padding-left: 0.4rem!important;
}
.custom-subhead {
  font-size: 0.875rem!important;
  line-height: 1.5!important;
  letter-spacing: 0.02857em!important;
  opacity: 1!important;
  text-transform: none!important;
  vertical-align: unset!important;
  text-decoration: none!important;
  color: rgb(123, 128, 154)!important;
  font-weight: 400!important;
  float: right!important;
}

.cst-btn {
  text-align: right!important;
  position: absolute!important;
  right: 1rem!important;
  top: 5.7rem!important;
  z-index: 100000!important;
}

}