/* .tss-1x5mjc5-MUIDataTable-root {
  padding: 0 0.8rem ;
} */
@media(min-width: 900px){

.custom-datatable{
  border-radius: 0.75rem!important;
}
.custom-datatable table{
  border-radius: 0.75rem!important;
  transform: rotateX(0deg)!important;
}
.custom-datatable h6,
.custom-datatable th {
  font-size: 16px ;
  font-weight: 600 ;
}

.custom-datatable td,
.custom-datatable th {
  text-align: center ;
  padding: 0px 10px ;
  border: 1px solid #eff2f7;
  background-color: #fff ;
}
.custom-datatable th button {
  color: black ;
}

.custom-datatable th div,
.custom-datatable td div {
  width: max-content;
}

/* width */
.custom-datatable table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-datatable table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-datatable table::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.custom-datatable table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

thead {
  display: block ;
}
tbody tr {
  display: flex ;
  border-bottom: none ;
}

.custom-datatable td div:nth-last-of-type(2) {
  display: none ;
  border-bottom: none ;
}

}