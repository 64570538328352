.details-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timeline-container {
  height: 250px; /* Adjust the height as needed */
  overflow: auto;
}

.timeline {
  display: flex;
  flex-direction: column;
}

.timeline-item {
  display: flex;
  margin-bottom: 20px;
}

.timeline-date {
  background-color: #f2f2f2;
  padding: 8px 12px;
  font-weight: bold;
}

.timeline-content {
  background-color: #f2f2f2;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
}
